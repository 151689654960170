







































































































































import { AxiosResponse } from "axios";
import Vue from "vue";
import content from "@/content.json";
import FindingsSummary from "@/components/FindingsSummary.vue";

export default Vue.extend({
  name: "VerbeterComponent",
  components: { FindingsSummary },
  data: () => ({
    page: content.pages.verbeter,
    count_text: content.pages.aantal_bevindingen,
    links: content.links,
    dialog: false,
    dialogOK: false,
    change: 0,
    cells: [],
    correction: undefined,
    succes: false,
    error: undefined,
    error_findings: undefined,
    anyChanges: false,
    content,
  }),
  methods: {
    collectCellData() {
      this.$data.cells = [];
      this.$data.change = 0;
      this.$data.correction = undefined;
      this.$data.succes = false;
      this.$data.error = undefined;
      this.$data.error_findings = undefined;
      this.$data.anyChanges = false;
    },
    previousCell() {
      this.$data.change -= 1;
      this.$data.succes = false;
      this.$data.error = undefined;
      this.$data.correction = undefined;
    },
    nextCell() {
      if (this.$data.cells.length >= this.$data.change + 1) {
        this.$data.change += 1;
        this.$data.succes = false;
        this.$data.error = undefined;
        this.$data.correction = undefined;
      }
    },
    commitCellChange() {
      if (this.$data.correction == undefined) {
        this.$data.error = "Nieuwe waarde mag niet leeg zijn";
        return;
      }

      const postURL = new URL(`${this.$store.state.APIurl}/cell/update`);
      postURL.searchParams.set(
        "cell_id",
        this.$data.cells.at(this.$data.change).id
      );
      postURL.searchParams.set("new_waarde", this.$data.correction);
      postURL.searchParams.set(
        "document_naam",
        this.$store.state.user.document.naam
      );
      this.$request
        .post(postURL.href)
        .then(() => {
          this.$data.cells.at(this.$data.change).modified = true;
          this.$data.cells.at(this.$data.change).correction =
            this.$data.correction;
          this.$data.succes = true;
          this.$data.anyChanges = true;
          this.$data.error = undefined;
        })
        .catch((error: any) => {
          this.$data.error = error;
        });
    },
    getVerbeterFindings: function () {
      this.$request
        .post<FormData, AxiosResponse<any>>(
          `${this.$store.state.APIurl}/document/validatie-correcties?doc_id=${this.$store.state.user.document.id}`
        )
        .then((response: any) => {
          const response_data: any = response.data;
          this.$store.dispatch("updateFindings", response_data.num_findings);
          this.collectCellData();
          this.$data.dialog = false;
          this.$data.anyChanges = false;
          this.cells = response_data.findings;

          //!!!!!! TODO, this is a major bodge since the backend is not working properly
          this.cells = this.cells.filter((x: any) => x.rule_id != null);

          // Sort by column and then row
          this.cells.sort(function (a: any, b: any) {
            return a.col_idx - b.col_idx || a.row_idx - b.row_idx;
          });
        })
        .catch((error: any) => {
          this.$data.error_findings = error;
        });
    },
    validateChanges() {
      this.change = 0;
      if (this.$data.anyChanges) {
        this.getVerbeterFindings();
        if (this.$data.anyChanges) {
          this.$data.dialogOK = true;
        }
      } else {
        this.$data.dialog = false;
      }
    },
  },
  created() {
    this.collectCellData();
    this.getVerbeterFindings();
  },
  computed: {
    numberFindings(): Record<string, number> {
      console.log(this.$store.state.user.numFindings);
      return this.$store.state.user.numFindings;
    },
  },
});
